import { ensureError } from '@orus.eu/error'
import { useCrash } from '@orus.eu/pharaoh'
import { useEffect, useState } from 'react'
import { useSession } from '../session'

export type PendingApiCallState = {
  readonly ready: false
  readonly data: null
}
export type ReadyApiCallState<RESULT> = {
  readonly ready: true
  readonly data: RESULT
}
export type ApiCallState<RESULT> = PendingApiCallState | ReadyApiCallState<RESULT>

type Api<ARGS extends unknown[], RESULT> = (...args: ARGS) => Promise<RESULT>

type ApiCallInternalState<ARGS extends unknown[], RESULT> = {
  api: Api<ARGS, RESULT>
  args: ARGS
  outcome: 'pending' | { result: RESULT }
}

export function useApi<ARGS extends unknown[], RESULT>(api: Api<ARGS, RESULT>, ...args: ARGS): ApiCallState<RESULT> {
  // ensure a session has been provided to avoir random API failure through race conditions
  void useSession()
  const crash = useCrash()
  const loadingState = { api, args, outcome: 'pending' } as const
  const [state, setState] = useState<ApiCallInternalState<ARGS, RESULT>>(loadingState)

  useEffect(() => {
    let cancelled = false
    setState(loadingState)

    api(...args).then(
      (result) => {
        if (cancelled) return

        setState({ api, args, outcome: { result } })
      },
      (err: unknown) => {
        if (cancelled) return

        const error = ensureError(err)
        // for dev
        console.error(error)

        crash({ type: 'unexpected-error', err: error })
      },
    )

    return () => {
      cancelled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, setState, ...args])

  const outcome = state.outcome
  if (outcome === 'pending') {
    return { ready: false, data: null }
  }
  if (!areIdenticalApiCalls(api, args, state.api, state.args)) {
    return { ready: false, data: null }
  }

  return { ready: true, data: outcome.result }
}

function areIdenticalApiCalls<ARGS extends unknown[], RESULT>(
  api1: Api<ARGS, RESULT>,
  args1: ARGS,
  api2: Api<ARGS, RESULT>,
  args2: ARGS,
): boolean {
  if (api1 !== api2) {
    return false
  }
  for (let i = 0; i < args1.length; i++) {
    if (args1[i] !== args2[i]) {
      return false
    }
  }
  return true
}
